import React from 'react'
import Link from 'next/link';
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from "next/router";
import dayjs from 'dayjs';


// controlelrs
import { _apiPost, _apiGet } from '@/controllers/api'
import { _groupBy } from '@/controllers/helpers'
import _actions from "@/controllers/redux/actions";

// components
import Layout from '@/components/Layout'
import Loading from '@/components/Loading';


// 
// 
const Home = () => {

	
	const dispatch = useDispatch()
    const router = useRouter()
	const auth = useSelector(state => state.auth.payload)
    const [ isLoading, setLoading ] = React.useState(true)
	const [ appointmentTypes, setAppointmentTypes ] = React.useState(null)


	// 
	// 
	const _parseAppointmentTypes = async(results) => {

		let finalArray = []

		for (let i = 0; i < results.length; i++) {
			const item = results[i];

			let newItem = {};

			const title = item.Name.split(" ", item.Name.split(" ").length - 1).join(
				" "
			);
			const typeAsString =
				item.Name.split(" ")[item.Name.split(" ").length - 1].toLowerCase();
			const type = typeAsString.substring(1, typeAsString.length - 1);

			newItem.title = title;
			newItem.excerpt = item.Notes.split(/\n/g)[0];
			newItem.type = type;
			// Check for existance of **NOTIFICATION** for alert copy
			const parts = item.Notes.split("**NOTIFICATION**");
			if (parts.length === 2) {
				newItem.description = `<p>${parts[0].replace(/\n/g, "<br />")}</p>`;
				newItem.notification = parts[1].replace(/\n/g, "");
			} else {
				newItem.notification = null;
				newItem.description = `<p>${item.Notes.replace(/\n/g, "<br />")}</p>`;
			}
			newItem[type] = { ...item };
			finalArray.push(newItem);
    	}

		const groupping =  _groupBy('title')
		const result    = groupping(finalArray)

		if(router?.query?.appointment){
			const findAppointment = Object.values(finalArray).find(x => x[x.type].Key===router.query.appointment)
			if(findAppointment){
				await _selectAppointmentType(findAppointment, result)
				return
			}
		}

		setAppointmentTypes(result)

	}


	// 
	// 
	const _selectAppointmentType = async(item, types={}) => {

		let _object = {
			expire:dayjs().add(1, 'hour').format(), 
			appointmentType:item, 
			appointmentTypes:appointmentTypes ? appointmentTypes : types
		}

		// 2543
		if(router?.query?.site){
			_object = {
				..._object,
				site:router?.query?.site
			}
		}

		// 153607
		if(router?.query?.clinician){
			_object = {
				..._object,
				clinician:router?.query?.clinician
			}
		}

        await dispatch(_actions.auth.authUpdate(_object))
		router.push('/appointment-search')

	}


	// 
	// 
	React.useEffect(()=>{

	  const init = async() => {
		console.log('index route');
		await dispatch(_actions.auth.authLogout())

		const params = {
			'client-key': process.env.NEXT_PUBLIC_CLIENT_KEY,
			'payer-type': process.env.NEXT_PUBLIC_PAYER_TYPE,
		}

		const get = await _apiGet('/patientportalapi/anon-appointment/types', { params:params })
		if(get?.result){
			await _parseAppointmentTypes(get.result)
		}

		setLoading(false)

	  }

	  init()

	},[])


	// 
	// 
	return (
		<Layout public>

			<div className='container'>

				<div className='flex justify-start items-start p-4 bg-custom-green-light border border-custom-green rounded'>
					<span className="material-symbols-outlined mr-2 -my-1">error</span>
					<p className="my-0">If you are already a registered client with us, and have completed one or more sessions, <Link href={process.env.NEXT_PUBLIC_LOGIN_LINK} className='underline' target="_blank">please login to the portal</Link> to book your follow up appointment</p>
				</div>

				<p className='mt-8 !mb-0'>Please click below to book one of the services available.</p>
				<p className='mb-8 !mt-2'>
					Your first consultation for any service is determined by your age as we have specialists for each age group.  
					So for example, if you are 18 or over, choose  "Adult initial consultation".  
					If  the booking is for someone under 12 years, choose "Child under 12 consultation". 
					The only exception to this is if you are booking an ADHD or ASD assessment - which is also age specific-choose either "Adult" or "Child/Adolescent". 
					Any questions?  Please contact us directly by phone on 01 611 1719 or email <a href="mailto:hello@mentalhealth.ie">hello@mentalhealth.ie</a>
				</p>

				{appointmentTypes && 
					<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
						{Object.values(appointmentTypes).map((item, index)=>{
							return(
								<div className='shadow-lg rounded-lg overflow-hidden flex flex-col flex-1' key={index}>
									<div className='bg-primary p-4 text-white text-base font-semibold flex'>{item[0].title}</div>
									<div className='flex flex-col flex-1 justify-between'>
										<div className='p-4'>{item[0].excerpt}</div>
										<div className='flex justify-start items-center gap-2 p-4'>
											{item.map((button,i)=>{
												return(
													<button key={i} className={`button ${button.type==='clinic' ? '' : 'outline'}`} onClick={()=>_selectAppointmentType(button)} data-key={button[button.type].Key}>{button.type==='clinic' ? 'In clinic' : 'Online'}</button>
												)
											})}
										</div>
									</div>
								</div>
							)
						})}
					</div>
				}

				<Loading status={isLoading} />

			</div>

		</Layout>
	)
}

export default Home
