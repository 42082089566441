import React from "react";
import Link from "next/link";
import Head from "next/head";
import { useSelector } from "react-redux";


// assets
import Logo from '@/assets/images/logo.png'


// 
// 
const Layout = (props) => {

    return(
        <>
        <Head>
        <title>Book an appointment - Centric Mental Health</title>
        </Head>
        <div className="layout">
        
            <header className="header">
                
                <div className="topHeader">
                    <div className="container">
                        <div>
                            <span className="material-symbols-outlined text-primary text-xl">call</span>
                            <span><Link href={'tel:016111719'} className="no-underline hover:text-primary">01 611 1719</Link> / <Link href={'tel:0212067001'} className="no-underline hover:text-primary">021 206 7001</Link></span>
                        </div>
                        <div>
                            <span className="pl-7 md:pl-0">Monday to Thursday  8.00 – 5.30pm | Friday 8.30am – 5.30pm | Saturday 10am – 14.00pm</span>
                        </div>
                    </div>
                </div>

                <div className="container py-6">
                    <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
                        <Link href={process.env.NEXT_PUBLIC_MAIN_WEBISTE_LINK} className="logo">
                            <img src={Logo.src} alt="Centric Mental Health"/>
                        </Link>

                        <div className="hidden md:flex justify-between md:justify-end items-center gap-4 w-full md:w-auto mt-4 md:mt-0">
                            <Link href={process.env.NEXT_PUBLIC_MAIN_WEBISTE_LINK} className="link"><span className="material-symbols-outlined">arrow_back</span> back to main website</Link>
                            <Link href={process.env.NEXT_PUBLIC_LOGIN_LINK} className="button" target="_blank">Login</Link>
                        </div>
                    </div>
                </div>

            </header>

            <div className="innerPage">
                {props.children}
            </div>

        </div>
        </>
    )
}

export default Layout
